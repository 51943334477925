import React, { Component } from 'react';
import {
  Button, Col, Row
} from 'reactstrap';
import { withRouter} from 'react-router-dom';
import { Loading } from './LoadingComponent';
import './BestDeals.css';
import ShopByMake from './../containers/ShopByMakeContainer';
import HowItWorks from "./HowItWorks";
import { ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from 'reactstrap';
import LazyLoad from 'react-lazyload';
import { accorladesData } from "../shared/promo_landing_data"
import AccoladesPromoElements from "./AccoladesPromoElements";
import fb from "../assets/single-elements/icons8-facebook-144.png"
import  insta  from "../assets/single-elements/icons8-instagram-144.png"
import twitter from "../assets/single-elements/icons8-twitter-144.png"


class BestDeals extends Component {
  constructor(props) {
    super(props);

    this.state = {
      image1: '',
      image2: '',
      image3: '',
      image4: '',
      isLoading: true,
      errMess: null,
      dealers: [],
      recommended: [],
      parts: [],
      selectedPriceTab: 1,
      selectedSearchedCarTab: 1,
      articleList: []
    };
  }

  componentDidMount() {
    this.props.getPromotionalApiData && this.props.getPromotionalApiData();
    this.props.getArticlesData && this.props.getArticlesData();

    if (this.props && this.props.location.state && this.props.location.state.pathname && this.props.location.state.pathname === "/login") {
      document.body.scrollTop = 0
      window.scrollTo(0,0);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let { getArticlesDataLoader, getArticlesDataSuccess, getArticlesDataError} = this.props
    if (getArticlesDataSuccess && prevProps.getArticlesDataSuccess !== getArticlesDataSuccess) {
      this.setState({
        articleList: getArticlesDataSuccess.articles
      })
    }
  }


  render() {
    let {
      getPromotionalApiDataSuccess,
      getPromotionalApiDataError,
      getPromotionalApiDataLoader,
      getArticlesDataLoader,
      getArticlesDataSuccess,
      getArticlesDataError

    } = this.props;

    let { selectedPriceTab, selectedSearchedCarTab, articleList } = this.state;
    let trustedCars = [];
    let mostSearched = [];
    let commercialVehicles = [];

    if (getPromotionalApiDataSuccess) {
      const {
        trusted_cars,
        most_searched,
        commercial_vehicles,
      } = getPromotionalApiDataSuccess;

      if (selectedPriceTab === 0) {
        trustedCars = trusted_cars.low_range;
      } else if (selectedPriceTab === 1) {
        trustedCars = trusted_cars.mid_range;
      } else if (selectedPriceTab === 2) {
        trustedCars = trusted_cars.upper_range;
      }

      if (selectedSearchedCarTab === 0) {
        mostSearched = most_searched.hatch_back;
      } else if (selectedSearchedCarTab === 1) {
        mostSearched = most_searched.sedan;
      } else if (selectedSearchedCarTab === 2) {
        mostSearched = most_searched.suv;
      }
    }


      return (
        <div className="dealer">
          {/* Best Deals Start */}
          <div
            className="best-deal"
            style={{
              backgroundColor: 'white',
              height: 'auto',
              paddingTop: '48px',
            }}
          >
            <div className="col-lg-6 col-sm-12 offset-lg-3">
              <div className="headings-div-successful" style={{ textAlign: 'center' }}>
                <h3 className="headings">Instant & Convenient Vehicle Insights</h3>
              </div>
            </div>
            <div className="container" style={{ paddingTop: '0px'}}>
              <div className="row">
                {accorladesData && accorladesData.map((d, i) => {
                   return (
                     <LazyLoad height={20} offset={100} once >
                       <AccoladesPromoElements d={d} i={i}>
                       </AccoladesPromoElements>
                     </LazyLoad>
                   );
                 })}
              </div>
            </div>
          </div>
          <div>
            <LazyLoad height={200} offset={100} once >
              <HowItWorks />
            </LazyLoad>
          </div>

          {/* Connect with us starts */}
          <div className="best-deal mt-4" style={{backgroundColor: 'white', height: 'auto', paddingBottom: '64px'}}>
            <div className="col-lg-6 col-sm-12 offset-lg-3">
              <div className="headings-div-successful" style={{ textAlign: 'center' }}>
                <h3 style={{ padding: '32px 0px' }} className="headings">Lets connect</h3>
              </div>
            </div>
            <div className="container" style={{textAlign: 'center'}}>
              <Row>
                <Col xs={12} sm={12}>
                  <a href="https://www.facebook.com/profile.php?id=61560652137850" target='_blank' rel="noopener noreferrer">
                    <img
                      src={fb}
                      alt="facebook"
                      className="lets-connect-icons"
                    />
                  </a>
                </Col>
                {/*<Col xs={4} sm={4}>*/}
                {/*  <a href="https://www.instagram.com/chicargos_auto/" target='_blank' rel="noopener noreferrer">*/}
                {/*    <img*/}
                {/*      src={insta}*/}
                {/*      alt="Instagram"*/}
                {/*      className="lets-connect-icons"*/}
                {/*    />*/}
                {/*  </a>*/}
                {/*</Col>*/}
                {/*<Col xs={4} sm={4}>*/}
                {/*  <a href="https://twitter.com/ChicargosA" target='_blank' rel="noopener noreferrer">*/}
                {/*    <img*/}
                {/*      src={twitter}*/}
                {/*      alt="Twitter"*/}
                {/*      className="lets-connect-icons"*/}
                {/*    />*/}
                {/*  </a>*/}
                {/*</Col>*/}
              </Row>
            </div>
          </div>
          {/* Connect with us ends */}


          {/* Research & Blog Start */}
          <div
            id="auto-parts"
            className="best-deal mt-4"
            style={{
              backgroundColor: 'white',
              height: 'auto',
              paddingBottom: '64px',
              display: 'None'
            }}
          >
            <div className="col-lg-6 col-sm-12 offset-lg-3">
              <div className="headings-div-successful" style={{ textAlign: 'center' }}>
                <h3 className="headings">News and Reviews</h3>
              </div>
            </div>
            <div className="container">
                { getArticlesDataLoader ?
                  <Col sm={12}>
                    <Loading />
                  </Col>
                  :
                  <div>
                    <div className="row">
                      {articleList && articleList.map((d, i) => {
                        return (
                          <Col sm={6}>
                            <ListGroup style={{margin: "15px"}}>
                              <ListGroupItem tag="a" href={`/latest/${d.slug}/${d.id}`}>
                                <ListGroupItemHeading style={{fontWeight: 700}}> {d.date_created}</ListGroupItemHeading>
                                <ListGroupItemText>
                                  {d.title}
                                </ListGroupItemText>
                              </ListGroupItem>
                            </ListGroup>
                          </Col>
                        );
                      })}
                    </div>
                  </div>
                }
              <div className="col-lg-3 col-lg-3 col-sm-6 col-xs-6 ">
              </div>
            </div>
          </div>
          {/* Research & Blog */}

        </div>
      );
  }
}

export default withRouter(BestDeals);
