import React from 'react';
import { Row, Col, Card } from "reactstrap";
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import Typography from '@material-ui/core/Typography'
import MotComponent from './MotComponent';
import { formatDate } from "../helpers/logic";

const VehicleReport = ({ currentResult, isImportedGood, isFinancedGood, isStolenGood,
                         isScrappedGood, isWrittenOffGood, isMOTGood, motMetaData, isTaxedGood, vedMetaData }) => {
  if (currentResult) {
    let vehImage = 'https://idaily-img.s3.eu-west-2.amazonaws.com/m-1u-05-09-24-11-26-29-exam-revision.jpg';
    const {
      VehicleImages: { ImageDetailsCount, ImageDetailsList },
      TechnicalDetails: { Performance, General, Dimensions },
      SmmtDetails: { BodyStyle },
      VehicleRegistration: {
        EngineNumber, DateFirstRegistered, VinLast5, Vrm, VehicleClass, SeatingCapacity, DoorPlan, Co2Emissions, Make,
        YearOfManufacture, Colour, Model, Imported, TransmissionType, GearCount, EngineCapacity, FuelType
      },
      VehicleHistory: { NumberOfPreviousKeepers, V5CCertificateCount, PlateChangeCount, PlateChangeList },
      MotHistory: { RecordCount, RecordList },
      VehicleStatus: { MotVed }
    } = currentResult;

    const { Power: { Bhp } } = Performance;
    const { KerbWeight, NumberOfDoors } = Dimensions;
    const { EuroStatus, DrivingAxle } = General;
    const { VedCurrentlyValid, VedExpiryDate, VedDaysRemaining, MotSornInPlace } = MotVed;

    if (ImageDetailsCount > 0) {
      // Parse the ExpiryDate string directly into a Date object
      let expiryDate = new Date(ImageDetailsList[0]["ExpiryDate"]);

      // Get today's date without the time for accurate comparison
      let today = new Date();
      today.setHours(0, 0, 0, 0); // Reset to midnight to compare dates only

      // Check that expiryDate is not in the past
      if (expiryDate > today) {
        vehImage = ImageDetailsList[0]["ImageUrl"];
      }
    }

    const formattedDateRegistered = formatDate(DateFirstRegistered);

    const furtherDetailArr = [
      { 'title': 'First Registered', 'value': formattedDateRegistered },
      { 'title': 'Number of Previous Keepers', 'value': NumberOfPreviousKeepers === 0 ? 1 : NumberOfPreviousKeepers },
      { 'title': 'V5 Certificates', 'value': V5CCertificateCount },
      { 'title': 'Vehicle Class', 'value': VehicleClass },
      { 'title': 'Vin Last 5', 'value': VinLast5 },
      { 'title': 'Door Plan', 'value': DoorPlan },
      { 'title': 'CO2 Emission', 'value': `${Co2Emissions} g/km` },
      { 'title': 'EuroStatus', 'value': EuroStatus },
      { 'title': 'Driven Axle', 'value': DrivingAxle },
      { 'title': 'Kerb Weight', 'value': KerbWeight },
      { 'title': 'Seating Capacity', 'value': SeatingCapacity },
      { 'title': 'Number Of Doors', 'value': NumberOfDoors },
    ];

    const genVehInfoArr = [
      { 'title': 'Make', 'value': Make },
      { 'title': 'Model', 'value': Model },
      { 'title': 'Colour', 'value': Colour },
      { 'title': 'Transmission', 'value': TransmissionType },
      { 'title': 'Gear Count', 'value': GearCount },
      { 'title': 'Engine Size', 'value': EngineCapacity },
      { 'title': 'BHP', 'value': Bhp },
      { 'title': 'Body Type', 'value': BodyStyle },
      { 'title': 'Fuel Type', 'value': FuelType },
      { 'title': 'Engine Number', 'value': EngineNumber },
    ];

    return (
      <div id="divToPrint">
        <Row style={{ textAlign: 'center', fontWeight: 'bolder', paddingBottom: "40px" }}>
          <Col>
            <div>
              <Typography variant="h6" className="report-headers">
                Your <span className="basic">basic</span> vehicle report
              </Typography>
            </div>
            <div>
              <Typography variant="subtitle1" className="typo-text">
                <span className="report-plate"> {Vrm} </span>
              </Typography>
            </div>
            <div>
              <Typography variant="subtitle1" className="typo-text">
                {Make} {Model} ({YearOfManufacture}) in {Colour}
              </Typography>
            </div>
          </Col>
          <Col>
            <div>
              <img
                style={{ height: '200px', paddingTop: '10px', paddingBottom: '15px' }}
                src={vehImage}
                alt="car image"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Row className={isTaxedGood ? "summary-boxes-success" : "summary-boxes-failure"}>
              <Col className="tax-mot-headers">
                <Typography variant="h6" className="tax-mot-header-text" gutterBottom>
                  TAX
                </Typography>
              </Col>
              <Col className="tax-mot-summary">
                {isTaxedGood ?
                  <CheckCircleRoundedIcon style={{ fill: "#22cc88", fontSize: "2rem" }} />
                  :
                  <CancelRoundedIcon style={{ fill: "#d77474", fontSize: "2rem" }} />
                }
              </Col>
            </Row>
          </Col>
          <Col>
            <Row className={isMOTGood ? "summary-boxes-success" : "summary-boxes-failure"}>
              <Col className="tax-mot-headers">
                <Typography variant="h6" className="tax-mot-header-text" gutterBottom>
                  MOT
                </Typography>
              </Col>
              <Col className="tax-mot-summary">
                {isMOTGood ?
                  <CheckCircleRoundedIcon style={{ fill: "#22cc88", fontSize: "2rem" }} />
                  :
                  <CancelRoundedIcon style={{ fill: "#d77474", fontSize: "2rem" }} />
                }
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ fontWeight: 800, paddingBottom: "64px" }}>
          <Col>
            <div>
              <Typography variant="h6" className="report-headers" gutterBottom>
                Checklist
              </Typography>

              {/*Taxed*/}
              <div className="report-div">
                <Row className={isTaxedGood ? "success-report" : "failure-report"}>
                  <Col className="key-data">
                    <Typography variant="body1">
                      Tax
                    </Typography>
                  </Col>
                  <Col className="value-data">
                    <Typography variant="body1">
                      {isTaxedGood ? <CheckRoundedIcon /> : <ClearRoundedIcon />} {isTaxedGood ? "Yes" : "No"}
                    </Typography>
                    {Object.entries(vedMetaData).map(([key, value]) => (
                      value !== null && (
                        <div className="report-meta" key={key}>
                          <strong>{key}:</strong> {value}
                        </div>
                      )
                    ))}
                  </Col>
                </Row>
              </div>

              {/*Imported*/}
              <div className="report-div">
                <Row className={isImportedGood ? "success-report" : "failure-report"}>
                  <Col className="key-data">
                    <Typography variant="body1">
                      Imported
                    </Typography>
                  </Col>
                  <Col className="value-data">
                    <Typography variant="body1">
                      {isImportedGood ? <CheckRoundedIcon /> : <ClearRoundedIcon />} {isImportedGood ? "No" : "Yes"}
                    </Typography>
                  </Col>
                </Row>
              </div>

              {/*Financed*/}
              <div className="report-div">
                <Row className="neutral-report">
                  <Col className="key-data">
                    <Typography variant="body1">
                      Finance Outstanding
                    </Typography>
                  </Col>
                  <Col className="value-data blurred">
                    <Typography variant="body1">
                      ???????
                    </Typography>
                  </Col>
                </Row>
              </div>

              {/*Stolen*/}
              <div className="report-div">
                <Row className="neutral-report">
                  <Col className="key-data">
                    <Typography variant="body1">
                      Stolen
                    </Typography>
                  </Col>
                  <Col className="value-data blurred">
                    <Typography variant="body1">
                      ???????
                    </Typography>
                  </Col>
                </Row>
              </div>

              {/*Scrapped*/}
              <div className="report-div">
                <Row className="neutral-report">
                  <Col className="key-data">
                    <Typography variant="body1">
                      Scrapped
                    </Typography>
                  </Col>
                  <Col className="value-data blurred">
                    <Typography variant="body1">
                      ???????
                    </Typography>
                  </Col>
                </Row>
              </div>

              {/*Written Off*/}
              <div className="report-div">
                <Row className="neutral-report">
                  <Col className="key-data">
                    <Typography variant="body1">
                      Written Off
                    </Typography>
                  </Col>
                  <Col className="value-data blurred">
                    <Typography variant="body1">
                      ???????
                    </Typography>
                  </Col>
                </Row>
              </div>

              {/*MOT*/}
              <div className="report-div">
                <Row className={isMOTGood ? "success-report" : "failure-report"}>
                  <Col className="key-data">
                    <Typography variant="body1">
                      MOT
                    </Typography>
                  </Col>
                  <Col className="value-data">
                    {isMOTGood ? <CheckRoundedIcon /> : <ClearRoundedIcon />} {isMOTGood ? "Yes" : "No"}
                    {Object.entries(motMetaData).map(([key, value]) => (
                      <div className="report-meta" key={key}>
                        <strong>{key}:</strong> {value}
                      </div>
                    ))}
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col>
            <Typography variant="h6" className="report-headers" gutterBottom>
              General Vehicle Info
            </Typography>
            <div className="report-meta">
              <Typography variant="subtitle2" gutterBottom>
                You should carefully check each value below to make sure that is corresponds to the vehicle
              </Typography>
            </div>
            {genVehInfoArr.map((obj, idx) => (
              obj.value !== null && (
                <div key={idx}>
                  <Row>
                    <Col className="key-data">
                      <Typography variant="body1" gutterBottom>
                        {obj.title}
                      </Typography>
                    </Col>
                    <Col className="value-data">
                      <Typography variant="body1" gutterBottom>
                        {obj.value}
                      </Typography>
                    </Col>
                  </Row>
                  <hr className="hr-data" />
                </div>
              )
            ))}
          </Col>
        </Row>
        <Row>
          {/*MOT History*/}
          <Col>
            <div>
              <Typography variant="h6" className="report-headers" gutterBottom>
                MOT History
              </Typography>
            </div>
            <Row>
              <Col>
                <Typography variant="body1">
                  MOT Count
                </Typography>
              </Col>
              <Col>
                <Typography variant="body1">
                  {RecordCount}
                </Typography>
              </Col>
            </Row>
            <Row>
              {RecordList && RecordList.map((d, i) => (
                <div key={i} className="mot-component-container">
                  <MotComponent d={d} i={i} />
                </div>
              ))}
            </Row>
          </Col>

          {/*Further Vehicle Checks*/}
          <Col>
            <div>
              <Typography variant="h6" className="report-headers" gutterBottom>
                Further Vehicle Checks
              </Typography>
            </div>
            {furtherDetailArr.map((obj, index) => (
              obj.value !== null && (
                <div key={index}>
                  <Row>
                    <Col className="key-data">
                      <Typography variant="body1" gutterBottom>
                        {obj.title}
                      </Typography>
                    </Col>
                    <Col className="value-data">
                      <Typography variant="body1" gutterBottom>
                        {obj.value}
                      </Typography>
                    </Col>
                  </Row>
                  <hr className="hr-data" />
                </div>
              )
            ))}
            <Row>
              <Col className="key-data">
                <Typography variant="body1" gutterBottom>
                  No of Plate Change(s)
                </Typography>
              </Col>
              <Col className="value-data">
                <Typography variant="body1" gutterBottom>
                  {PlateChangeCount}
                </Typography>
                <div>
                  {PlateChangeList && PlateChangeList.map((change, idx) => (
                    <div key={idx}>
                      <Typography variant="body1" gutterBottom>
                        From: <span className="report-plate"> {change.PreviousVRM} </span> &nbsp;To: <span className="report-plate"> {change.CurrentVRM} </span>
                      </Typography>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  } else {
    return (
      <div className="col-12 col-md-12 col-lg-12 mt-12">
        <Card>No record Found</Card>
      </div>
    );
  }
};

export default VehicleReport;
