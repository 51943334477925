import React, {Component} from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import {Link, withRouter} from 'react-router-dom';
import { withCookies } from 'react-cookie';
import VisaLogo from '../../assets/images/visa.png';
import onImage from '../../assets/images/no-image.jpg';
import CarDetail from './CarDetailComponent';
import CarBuyDetail from './CarBuyDetailComponent';
import CarBuyAck from './CarBuyAckComponent';
import PhoneVerification from './PhoneVerification'
import PhoneVerificationConfirm from "./PhoneVerificationConfirm";
// import CardPayment from "./CardPayment";
// import CardPaymentStripe from "./CardPaymentStripe";
import StripeIndex from "./OnlinePayment/StripeIndex";
// import OStripeIndex from "./OnlinePayment/OStripeIndex";
import SomeComponent from './ReviewOrder'
import ConfirmOrder from './ConfirmOrder'
import Stepper from 'react-stepper-horizontal';
import {compose} from "redux";
import {Helmet} from "react-helmet";
import credentials from "../../config/credentials";


class Car extends Component {
    constructor(props) {
        super(props);

        this.handleDeliveryCalculation = this.handleDeliveryCalculation.bind(this)

        this.state = {
            url: "",
            upc: "",
            id: 0,
            title: "",
            description: "",
            structure: "",
            date_created: "",
            date_updated: "",
            recommended_products: [],
            attributes: [],
            categories: [],
            product_class: "",
            stockrecords: "",
            images: [],
            price: "",
            PriceObj: {},
            availability: "",
            options: [],
            children: [],
            specs: [],
            specsOther: [],
            paidProducts: [],
            port: "",
            countries: [],
            destinationCountry: "",
            destinationPort: "",
            destinationCode: "free-shipping",
            basket: {},
            MultiView: 0,
            message: "",
            userEmail: "",
            firstName: "",
            lastName: "",
            contactNumber: "",
            line1: "",
            line2: "",
            city: "",
            province: "",
            country: "United Kingdom",
            postCode: "",
            email: "",
            userTitle: "",
            activeStep: 1,
            showModal: false,
            isModalOpen: false,
            modalMessage: "",
            shippingChargeOption: {},
            totalCost: 0,
            userSelectedDestination: {},
            minimumFreeDeliveryTotal: 25,
            guestCheckout: false,
            payment: "",
            verifyNumber: "",
            verificationCode: "",
            verificationStatus: "not-requested",
            requestButtonDisabled: false,
            notification: "",
            error: false,
            phoneValidationSuccess: false,
            payPalObj: [],
            ageVerificationModal: false
        }
    }


    componentDidMount() {
        this._isMounted = true;
        const { location: { state } } = this.props;
        const self = this;
        if (state && state.destinationCountry && state.destinationPort) {
            self.setState({ destinationCountry: state.destinationCountry, destinationPort: state.destinationPort })
        }
        this.props.getCountries && this.props.getCountries();

        const { MultiView } = this.props;
        if (this.props.location.pathname === "/checkout") {
            const basket = this.props.location.state.results
            const destinationPort = this.props.location.state.destinationPort
            // const shippingChargeOption = this.handleDeliveryCalculation()
            let { getUserBasketSuccess } = this.props
            const userSelectedDestination = this.props.location.state.userSelectedDestination
            let totalInclShipping = (
              parseFloat(this.props.location.state.totalCost) +
              parseFloat(getUserBasketSuccess.deliveryCharge.incl_tax)).toFixed(2)
            this.setState({ MultiView });
            this.setState({ basket: { ...basket },
              destinationPort: destinationPort,
              destinationCode: getUserBasketSuccess.deliveryCode,
              totalCost: totalInclShipping,
              shippingChargeOption: getUserBasketSuccess.deliveryCharge,
              userSelectedDestination: userSelectedDestination,
              ageVerificationModal: true,
              guestCheckout: false
            })
          this.props.getUserBasket && this.props.getUserBasket()
        }
        if (this.props.location.pathname === "/guest-checkout") {
          this.setState({MultiView})
          this.setState({
            ageVerificationModal: true, guestCheckout: true,
          })
          this.props.getUserBasket && this.props.getUserBasket()
        }
        if (this.props.location.pathname === "/review-order"){
             this.setState({
            ...this.props.location.state
            })
            this.setState({

              MultiView: 3,
              activeStep: 2
            })
        }
      if (this.props.location.pathname === "/phone-verification"){
        this.setState({
          ...this.props.location.state
        })
        this.setState({
          MultiView: 6,
          activeStep: 3
        })
      }
      if (this.props.location.pathname === "/phone-verification-confirm"){
        this.setState({
          ...this.props.location.state
        })
        this.setState({
          MultiView: 7,
          activeStep: 3
        })
      }
      if (this.props.location.pathname === "/card-payment-stripe"){
        this.setState({
          ...this.props.location.state
        })
        this.setState({
          MultiView: 9,
          activeStep: 3
        })
      }
        if (this.props.location.pathname === "/confirm-order"){
          this.setState({
            MultiView: 4,
            activeStep: 4,
            notification: this.props.location.message,
            error: this.props.location.error,
            paidProducts: this.props.location.paidProducts
          });
        }
    }

    toggle = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      showModal: false,
    });
    }

  toggleAgeModal = () => {
    this.setState({
      ageVerificationModal: false
    });
  }

    onChange = (e) => {
      const { name, value } = e.target;
      this.setState({ [`${name}`]: value, notification: "", error: false });
    }


    handleKeyPress(target) {
    }

  handleDeliveryCalculation() {
    let shippingCharge = {}
    let methodObj = {}
    let { getUserBasketSuccess, getShippingMethodsSuccess } = this.props
    if (getUserBasketSuccess.total < this.state.minimumFreeDeliveryTotal) {
      methodObj = getShippingMethodsSuccess.find(o => o.code === "base-shipping");
      shippingCharge["value"] = methodObj["price"]["incl_tax"]
      shippingCharge["name"] = methodObj["name"]
      shippingCharge["code"] = methodObj["code"]
    } else {
      methodObj = getShippingMethodsSuccess.find(o => o.code === "free-shipping")
      shippingCharge["value"] = 0
      shippingCharge["name"] = "Free Delivery"
      shippingCharge["code"] = methodObj["code"]
    }
    return shippingCharge
  }

    onBuy = (MultiView, mobile) => {
      const {postCode} = this.state
      const canDeliver = this.handlePostcodeChecker(postCode)
      canDeliver.then(response => response)
        .then(data => {
          if (data===200) {
            const { history } = this.props;
            history.push({
              pathname: '/review-order',
              state: this.state
            });
            this.setState({ MultiView, message: "", activeStep: 1 });
          } else {
            const errorMsg = `Sorry, we dont deliver to ${postCode} yet!`
            this.setState({error: true, notification: errorMsg})
            window.scrollTo({ top: 100, behavior: 'smooth' })
            return
          }
        })
        .catch();
    }

  onMyAccount = () => {
    const { history } = this.props;
    history.push({
        pathname: '/profile'
    })
  }

  onContinueShopping = () => {
    const { history } = this.props;
    history.push({
      pathname: '/'
    })
  }

  onLogin = () => {
    const { history } = this.props;
    history.push({
        pathname: '/login'
    })
  }

  onPaymentGateway = (e) => {
    const {payment} = this.state

    if (payment === "") {
      alert("Please select a payment method")
      return
    }
    const { history } = this.props;
    if (payment === "visa") {
      history.push({
        pathname: "/card-payment-stripe",
        state: this.state
      });
    } else if (payment === "cash") {
      history.push({
        pathname: '/phone-verification',
        state: this.state
      });
    }
  }

  handlePostcodeChecker = async postCode => {
    let response = await fetch(`${credentials.API_URL}/postcode-check/?pc=${postCode}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data = await response.json();
    return data
  }


  formatPhoneNumber(phoneNumber){
      if (phoneNumber.startsWith("+44")) {
        return phoneNumber
      } else if (phoneNumber.startsWith("0") || (phoneNumber.startsWith(0))) {
        let cleanNumber = phoneNumber.substring(1);
        cleanNumber = `+44${cleanNumber}`
        return cleanNumber
      } else {
        return null
      }
  }

  onValidatePhone = (e) => {
    const {verifyNumber} = this.state
    if (verifyNumber === ""){
      alert("Enter your phone number")
      return
    } else {
      const mobileNumber = this.formatPhoneNumber(verifyNumber)
      if (mobileNumber === null){
        alert("Please enter a valid UK number")
        return
      }
      const payload = {"phone_number": mobileNumber}
      this.props.validatePhone && this.props.validatePhone(payload)
    }
  }

  onValidatePhoneConfirm = (e) => {
    const {verificationCode, verifyNumber} = this.state
    const mobileNumber = this.formatPhoneNumber(verifyNumber)
    if (verificationCode === ""){
      alert("Enter the verification code")
      return
    } else {
      const token = localStorage.getItem("phone_session_token")
      let payload = {
        "phone_number": mobileNumber,
        "security_code": verificationCode,
        "session_token": token
      }
      this.props.validatePhoneConfirm && this.props.validatePhoneConfirm(payload)
    }
  }

  onConfirm = (MultiView) => {
    const token = localStorage.getItem('token')
    const selectedCountry = "http://localhost:8024/api/countries/GB/"
    const { payment } = this.state
    const obj = this.state.shippingChargeOption
    const shippingAddress = {
      title: this.state.userTitle,
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      line1: this.state.line1,
      line2: this.state.line2,
      line3: "",
      line4: "",
      state: this.state.province,
      postcode: this.state.postCode,
      phone_number: this.state.contactNumber,
      tel_number: this.state.contactNumber,
      notes: "",
      country: selectedCountry
      }
    const billingAddress = {
      title: this.state.userTitle,
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      line1: this.state.line1,
      line2: this.state.line2,
      line3: "",
      line4: "",
      state: this.state.province,
      postcode: this.state.postCode,
      phone_number: this.state.contactNumber,
      notes: "",
      country: selectedCountry
      }
    let paymentObj = {
      cash: {
        enabled: true,
        amount: this.state.totalCost
      }
    }
    if (payment !== "cash") {
      paymentObj = {
        paypal: {
          enabled: true,
          amount: this.state.totalCost
        }
      }
    }

    if (this.state.guestCheckout) {
      this.props.placeOrder && this.props.placeOrder({
        dataPayload: {
          basket: this.state.basket[0].basket,
          guest_email: this.state.userEmail,
          total: this.state.totalCost,
          shipping_charge: obj,
          shipping_method_code: this.state.destinationCode,
          shipping_address: JSON.stringify(shippingAddress),
          billing_address: JSON.stringify(billingAddress),
          payment: JSON.stringify(paymentObj)},
        guestCheckout: this.state.guestCheckout
      })
    } else {
      this.props.placeOrder && this.props.placeOrder({
        dataPayload: {
          basket: this.state.basket[0].basket,
          total: this.state.totalCost,
          shipping_charge: JSON.stringify(obj),
          shipping_method_code: this.state.destinationCode,
          shipping_address: JSON.stringify(shippingAddress),
          billing_address: JSON.stringify(billingAddress),
          payment: JSON.stringify(paymentObj)},
        sessionToken: token,
        guestCheckout: this.state.guestCheckout
      })
    }
  }

    componentDidUpdate(prevProps) {
      let {
        getProductsSuccess,
        addProductToBasketSuccess,
        placeOrderSuccess,
        placeOrderError,
        getUserBasketSuccess,
        getShippingMethodsSuccess,
        apiPhoneValidationSuccess,
        apiPhoneValidationConfirmSuccess,
        apiPhoneValidationConfirmError,
        history
      } = this.props;
      if (getProductsSuccess && prevProps.getProductsSuccess !== getProductsSuccess) {
        this.setState({ url: getProductsSuccess.url });
      } else if (addProductToBasketSuccess && prevProps.addProductToBasketSuccess !== addProductToBasketSuccess) {
        this.setState({ MultiView: 2, message: "" });
      } else if (placeOrderSuccess && prevProps.placeOrderSuccess !== placeOrderSuccess) {
        this.props.clearCart("Nada")
        let alertSuccess = `Your order number is ${placeOrderSuccess.number}`
        history.push({
          pathname: '/confirm-order',
          message: alertSuccess,
          paidProducts: placeOrderSuccess.paid_products,
          error: false,
          state: this.state
        })
      } else if (placeOrderError && prevProps.placeOrderError !== placeOrderError) {
        let oops = <p>
          Oops, something went wrong, Call us on 0330 055 0498
          <br/>
          <hr/>
          Ref: {placeOrderError}
        </p>
        if (placeOrderError.includes("401")){
          oops = <p>
            Oops, something went wrong, please try placing the order again
          </p>
        }

        this.setState({error: true, notification: oops})
        return
      }
      if (getUserBasketSuccess &&
        prevProps.getUserBasketSuccess !== getUserBasketSuccess) {
        if (getUserBasketSuccess.results !== null) {
          if (getShippingMethodsSuccess) {
            let totalInclShipping = (
              parseFloat(getUserBasketSuccess.total) +
              parseFloat(getUserBasketSuccess.deliveryCharge.incl_tax)).toFixed(2)
            this.setState({
              basket: { ...getUserBasketSuccess.results},
              shippingChargeOption: getUserBasketSuccess.deliveryCharge,
              totalCost: totalInclShipping,
              destinationCode: getUserBasketSuccess.deliveryCode,
              payPalObj: getUserBasketSuccess.payPalObj
            })
          }
        }
      }
      if (apiPhoneValidationSuccess &&
        prevProps.apiPhoneValidationSuccess !== apiPhoneValidationSuccess) {
        history.push({
          pathname: "/phone-verification-confirm",
          state: this.state
        });
      }
      if (apiPhoneValidationConfirmSuccess &&
        prevProps.apiPhoneValidationConfirmSuccess !== apiPhoneValidationConfirmSuccess) {
        const phoneSuccessMsg = "Verification code accepted. Placing order"

        this.setState({
          error: false,
          notification: phoneSuccessMsg,
          phoneValidationSuccess: true
        })
        this.onConfirm()
      }
      if (apiPhoneValidationConfirmError &&
      prevProps.apiPhoneValidationConfirmError !== apiPhoneValidationConfirmError) {
        let errorMessage = JSON.parse(apiPhoneValidationConfirmError.error)['non_field_errors'][0]
        this.setState({error: true, notification: errorMessage})
      }
    }

    onBuyOrder = (MultiView, mobile) => {
      const { url, destinationCountry, destinationPort } = this.state;
      const { history, cookies, location } = this.props;
      const mv = mobile ? 1 : 0;
      if (cookies.get('email') === undefined) {
        history.push("/login", { redirect: location, destinationCountry, destinationPort });
        return;
      }
      if (destinationCountry === "" || destinationPort === "") {
        this.setState({ message: "Destination missing", MultiView: mv });
        return;
      }
      else {
        this.props.addProductToBasket && this.props.addProductToBasket({ endpointAndQuantity: {url, quantity: 1},
                                                                           sessionToken: this.props.allCookies });
      }
    }


    render() {
      var { state } = this;
      let { getCountriesSuccess, getSpecificationsSuccess, getPriceSuccess, placeOrderLoader } = this.props;
      let finalData = null;
      const actionStep  = state.activeStep
      if ( getCountriesSuccess && getSpecificationsSuccess && getPriceSuccess) {
        finalData = {
          message: state.message,
          destinationCountry: state.destinationCountry,
          destinationPort: state.destinationPort,
          PriceObj: getPriceSuccess,
          countries: getCountriesSuccess,
        }
      }
      return (
        <div>
          <Helmet>
            <meta name="description"
                  content="Sign up to Ex UK Online for the best import deals on Vehicles, Auto Parts and Electronics and much more. " />
            <title>Reg Check Mate</title>
          </Helmet>
          <div className="container cpanel" style={{backgroundColor: '#f8f9fa'}}>
          {state.MultiView === 0 &&
            <div>
              <Breadcrumb>
                <BreadcrumbItem><Link to="/">Vechile</Link></BreadcrumbItem>
              </Breadcrumb>

              <CarDetail details={finalData ? finalData : state} VisaLogo={VisaLogo} onImage={onImage} onBuy={this.onBuy} onChange={this.onChange} />
            </div>}
            <div className="stepper-background">
              <div className="stepper-spacer">
              </div>
              <Stepper
                steps={ [{title: "Delivery"}, {title: "Review"}, {title: "Process"}, {title: "Order"}] }
                activeStep={ actionStep }
                size={30}
                circleFontSize={10}
                titleFontSize={12}
                circleTop={0}
                activeColor={'#989798'}
                completeColor={'#000000'}
              />
            </div>
          {state.MultiView === 1 &&
            <div>
              <CarBuyDetail
                onBuy={this.onBuy}
                details={state}
                VisaLogo={VisaLogo}
                onImage={onImage}
                onChange={this.onChange}
                handleKeyPress={this.handleKeyPress}
                showAgeModal={this.state.ageVerificationModal}
                toggleAgeModal={this.toggleAgeModal}
                error={this.state.error}
                notification={this.state.notification}
              />
            </div>
          }
          {
            state.MultiView === 2 &&
            <CarBuyAck />
          }
          {
            state.MultiView === 3 &&
            (<SomeComponent
              someProp={state}
              toggle={this.toggle}
              onLogin={this.onLogin}
              onLoading={placeOrderLoader}
              onCountries={getCountriesSuccess}
              onChange={this.onChange}
              onPayment={this.onPaymentGateway}
            />)
          }
          {
            state.MultiView === 6 &&
            <PhoneVerification
              method="Cash Payment"
              onChange={this.onChange}
              onValidatePhone={this.onValidatePhone}
              buttonDisabled={state.requestButtonDisabled}
              loader={this.props.apiPhoneValidationLoader}
            />
          }
            {
              state.MultiView === 7 &&
              <PhoneVerificationConfirm
                onChange={this.onChange}
                onConfirmCode={this.onValidatePhoneConfirm}
                phoneNumber={this.state.verifyNumber}
                loader={this.props.apiPhoneValidationConfirmLoader || placeOrderLoader}
                error={this.state.error}
                notification={this.state.notification}
                phoneValidationSuccess={this.state.phoneValidationSuccess}
              />
            }
          {
          state.MultiView === 9 &&
            // <CardPaymentStripe
            //   order={this.state.payPalObj}
            //   onConfirm={this.onConfirm}
            // />
              <StripeIndex
                order={this.state.payPalObj}
                onConfirm={this.onConfirm}
              />
          }
          {
            state.MultiView === 4 &&
            <ConfirmOrder
              onMyAccount={this.onMyAccount}
              onContinueShopping={this.onContinueShopping}
              error={this.state.error}
              notification={this.state.notification}
              paidProducts={this.state.paidProducts}
            />
          }
        </div>
        </div>
      );
    }
}

export default compose(withCookies, withRouter)(Car);

