import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import {STRIPE_KEY} from "../../../store/constants";

// Make sure to call loadStripe outside of a component’s render to avoid recreating the Stripe object on every render.
const promise = loadStripe(STRIPE_KEY);

// Initialize Stripe Elements
export default function StripeIndex(props) {

    const { order, onConfirm } = props;
    return (
        <div className="App">
            <Elements stripe={promise}>
                <CheckoutForm
                    order={order}
                    onConfirm={onConfirm}
                />
            </Elements>
        </div>
    );
}